@layer components {
  .heading {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: theme('colors.fontColor');
  }

  h1,
  .h1 {
    @extend .heading;
    font-size: 28px;
    line-height: 32px;
  }

  h2,
  .h2 {
    @extend .heading;
    font-size: 24px;
    line-height: 28px;
  }

  h3,
  .h3 {
    @extend .heading;
    font-size: 22px;
    line-height: 26px;
  }

  h4,
  .h4 {
    @extend .heading;
    font-size: 18px;
    line-height: 24px;
  }

  h5,
  .h5 {
    @extend .heading;
    font-size: 16px;
    line-height: 22px;
  }

  .text-base {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .text-base-light {
    @extend .text-base;
    font-weight: 300 !important;
  }

  .text-base-medium {
    @extend .text-base;
    font-weight: 600 !important;
  }

  .text-base-bold {
    @extend .text-base;
    font-weight: 700 !important;
  }

  .text-sm {
    @extend .text-base;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .text-sm-light {
    @extend .text-sm;
    font-weight: 300 !important;
  }

  .text-sm-medium {
    @extend .text-sm;
    font-weight: 600 !important;
  }

  .text-sm-bold {
    @extend .text-sm;
    font-weight: 700 !important;
  }

  .text-xs {
    @extend .text-base;
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .text-xs-light {
    @extend .text-xs;
    font-weight: 300 !important;
  }

  .text-xs-medium {
    @extend .text-xs;
    font-weight: 600 !important;
  }

  .text-xs-bold {
    @extend .text-xs;
    font-weight: 700 !important;
  }

  .text-lg {
    @extend .text-base;
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .text-lg-light {
    @extend .text-lg;
    font-weight: 300 !important;
  }

  .text-lg-medium {
    @extend .text-lg;
    font-weight: 600 !important;
  }

  .text-lg-bold {
    @extend .text-lg;
    font-weight: 700 !important;
  }

  .text-xl {
    @extend .text-base;
    font-size: 20px !important;
    line-height: 26px !important;
  }

  .text-xl-light {
    @extend .text-xl;
    font-weight: 300 !important;
  }

  .text-xl-medium {
    @extend .text-xl;
    font-weight: 600 !important;
  }

  .text-xl-bold {
    @extend .text-xl;
    font-weight: 700 !important;
  }

  .text-2xl {
    @extend .text-base;
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .text-2xl-light {
    @extend .text-2xl;
    font-weight: 300 !important;
  }

  .text-2xl-medium {
    @extend .text-2xl;
    font-weight: 600 !important;
  }

  .text-2xl-bold {
    @extend .text-2xl;
    font-weight: 700 !important;
  }

  .text-3xl {
    @extend .text-base;
    font-size: 28px !important;
    line-height: 32px !important;
  }

  .text-3xl-light {
    @extend .text-3xl;
    font-weight: 300 !important;
  }

  .text-3xl-medium {
    @extend .text-3xl;
    font-weight: 600 !important;
  }

  .text-3xl-bold {
    @extend .text-3xl;
    font-weight: 700 !important;
  }

  .link {
    text-decoration: underline;

    @media (hover) {
      &:hover {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }

  .link-dark {
    @extend .link;

    @media (hover) {
      &:hover {
        color: #699cc9;
      }
    }

    &:active {
      color: #c3c8cb;
    }
  }

  code {
    word-wrap: break-word;
  }
}
